.footer {
  background-color: var(--footerBg);
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  row-gap: 40px;
  column-gap: 40px;
  z-index: 2;
  position: relative;
  span, p, a, div, svg path {
    color: var(--footerTxtInitial);
    fill: var(--footerTxtInitial);
  }
  a:hover {
    color: var(--footerTxtHover);
  }
  p {
    margin: 0;
  }
  svg {
    width: 30px;
    height: auto;
    max-width: unset;
  }
  .title {
    display: block;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .logo {
    img {
      height: auto;
    }
  }
  .social {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }
  .social svg {
    transition: ease 0.3s all;
    opacity: 0.8;
    &:hover {
      opacity: 1;
      path {
        fill: var(--footerTxtHover);
        stroke: var(--footerTxtHover);
      }
    }
    path {
      transition: ease 0.3s all;
    }
  }
  .form span {
    color: $paragraph-color;
  }
  .form {
    background-color: #fff;
    padding: 40px;
    max-width: 700px;
    margin-bottom: 0;
    @include mq($until: lg){
      max-width: 100%;
    }
  }
  .nav {
    &.start {
      justify-content: flex-start;
    }
    &.end {
      justify-content: flex-end;
    }
    &.center {
      justify-content: center
    }
    &.space {
      .header__menuList {
        width: 100%;
        justify-content: space-between;
        column-gap: 10px;
      }
      .header__menuLink {
        padding: 20px 0px;
      }    
    }
    .header__menuList {
      padding: 0;
    }
    .header__menuLink:active, .header__menuLink--active, .header__menuLink--open {
      color: var(--footerTxtInitial);
    }
    .header__menuLink:hover {
      color: var(--footerTxtHover);
    }
  }
  .block {
    padding: 40px;
    background: rgba(255, 255, 255, 0.03);
    transition: ease 0.3s all;
  }
  .contact {
    .list {
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      a {
        display: flex;
        column-gap: 15px;
        align-items: center;
        &:hover {
          svg, svg path {
            fill: var(--footerTxtHover);
            stroke: var(--footerTxtHover);
          }
        }
        svg, svg path {
          transition: ease 0.3s all;
        }
      }
    }
  }
  &.icons-bg {
    svg {
      width: 40px;
      height: 40px;
      padding: 10px;
      background: rgba(255, 255, 255, 0.03);
      transition: ease 0.3s all;
    }
    a:hover svg {
      fill: var(--footerTxtInitial);
      background-color: var(--footerTxtHover);
      path {
        fill: var(--footerTxtInitial);
        stroke: var(--footerTxtInitial);
      }
    }
    .block svg {
      background: rgba(255, 255, 255, 0.03);
    }
  }
  &.icons-border {
    svg {
      width: 40px;
      height: 40px;
      padding: 10px;
      border: 1px solid rgba(255, 255, 255, 0.05);
      transition: ease 0.3s all;
    }
    a:hover svg {
      border-color: var(--footerTxtHover);
      fill: var(--footerTxtHover);
      path {
        fill: var(--footerTxtHover);
        stroke: var(--footerTxtHover);
      }
    }
  }



  &.footer01 {
    justify-content: space-between;
    &.grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      @include mq($until: xl){
        grid-template-columns: 1fr 1fr;
      }
      @include mq($until: sm){
        grid-template-columns: 1fr;
      }
    }
    @include mq($until: xl){
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 40px;
    }
    @include mq($until: sm){
      grid-template-columns: 1fr;
    }
  }



  &.footer02 {
    display: grid;
    grid-template-columns: minmax(200px, 350px) minmax(200px, 350px) auto;
    @include mq($until: xl){
      grid-template-columns: 1fr 1fr;
    }
    @include mq($until: sm){
      grid-template-columns: 1fr;
    }
    .logo {
      display: block;
      margin-bottom: 20px;
    }
    & > div:last-of-type {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      @include mq($until: xl){
        justify-content: flex-start;
      }  
      .title {
        margin-bottom: 0;
      }
    }
  }

  &.footer03 {
    display: grid;
    grid-template-columns: minmax(300px, 50%) auto;
    align-items: start;
    padding-bottom: 80px;
    @include mq($until: 1800px) {
      grid-template-columns: minmax(300px, 40%) auto;
    }
    @include mq($until: lg) {
      grid-template-columns: minmax(300px, 50%) auto;
    }
    @include mq($until: md){
      grid-template-columns: 1fr;
    }
    .logo {
      display: block;
      margin-bottom: 20px;
    }
    .contact {
      row-gap: 40px;
      column-gap: 40px;
      display: grid;
      align-items: start;

      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      @include mq($until: 1800px) {
        grid-template-columns: 1fr;
        grid-template-rows: unset;
      }
      @include mq($until: md){
        grid-template-columns: 1fr 1fr;
        align-items: stretch;
      }
      @include mq($until: sm){
        grid-template-columns: 1fr;
      }  
      & div:nth-child(1) { 
        grid-area: 1 / 1 / 2 / 3;
        @include mq($until: 1800px) {
          grid-area:unset;
        }
        @include mq($until: md){
          grid-area: 1 / 1 / 2 / 3;
        }  
        @include mq($until: sm){
          grid-area:unset;
        }  
        p {
          font-size: rem(26px);
          font-weight: 500;
        }
      }

      & div:nth-child(2) {
        grid-area: 2 / 1 / 3 / 2;
        @include mq($until: 1800px) {
          grid-area:unset;
        }
        p:first-of-type {
          margin-bottom: 10px;
        }
      }

      & div:nth-child(3) {
        grid-area: 2 / 2 / 3 / 3;
        @include mq($until: 1800px) {
          grid-area:unset;
        }
      }
      svg {
        width: 20px;
        height: 20px;
      }
      p {
        line-height: unset;
      }
    }
    .form {
      margin-top: -35vh;
      @include mq($until: lg) {
        margin-top: -400px;

        &__group {
          flex-direction: column;
        }
      }
      @include mq($until: md){
        order: 2;
        margin-top: 0;
        &__group {
          flex-direction: row;
        }
      }
      @include mq($until: xs){
        &__group {
          flex-direction: column;
        }
      }
    }
    .social {
      margin-top: 30px;
      justify-content: flex-end;
    }
    & > div:last-of-type {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      row-gap: 40px;
      @include mq($until: md){
        align-items: flex-start;
      }
      .title {
        margin-bottom: 0;
      }
    }
  }



  &.footer04 {
    display: grid;
    grid-template-columns: minmax(300px, 50%) auto;
    @include mq($until: xl){
      grid-template-columns: auto 400px;
    }
    @include mq($until: lg){
      grid-template-columns: 1fr;
    }
    .block:nth-child(2),.block:nth-child(3) {
      background-color: transparent;
    }
    @include mq($until: sm){
      .block:nth-child(3) {
        background: rgba(255, 255, 255, 0.03);
      }
      .block:nth-child(4) {
        background-color: transparent;
      }
    }
    .left {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: stretch;
      gap: 40px;
      &.blocks {
        gap: 0;
      }
      @include mq($until: sm){
        grid-template-columns: 1fr;
      }
    }
    .logo {
      display: block;
      margin-bottom: 20px;
    }
    & > div:last-of-type {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      row-gap: 40px;
      .title {
        margin-bottom: 0;
      }
    }
  }



  &.footer05 {
    flex-direction: column;
    .top {
      display: grid;
      grid-template-columns: minmax(200px, 25%) auto;
      align-items: center;
      @include mq($until: md){
        grid-template-columns: 1fr;
        row-gap: 40px;
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      column-gap: 40px;  
      padding-top: 60px;
      border-top: 1px solid rgba(255, 255, 255, 0.05);
      @include mq($until: xl){
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 40px;
      }
      @include mq($until: sm){
        grid-template-columns: 1fr;
      }
      &.grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        @include mq($until: xl){
          grid-template-columns: 1fr 1fr;
        }
        @include mq($until: sm){
          grid-template-columns: 1fr;
        }  
      }
      .social {
        margin-top: 20px;
      }
    }
  }
  &.footer06 {
    justify-content: space-between;
    @include mq($until: lg){
      flex-direction: column;
    }
    .left { 
      row-gap: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include mq($until: lg){
        flex-direction: column-reverse;
      }  
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 40px;
        @include mq($until: lg){
          flex-direction: column;
          align-items: flex-start;
          row-gap: 40px;
        }  
        .title {
          margin: 0;
        }
      }
      .nav {
        border-top: 1px solid rgba(255, 255, 255, 0.05);
        padding-top: 20px;
        @include mq($until: lg){
          border-top: none;
          border-bottom: 1px solid rgba(255, 255, 255, 0.05);
          padding-top: 0;
          padding-bottom: 40px;
        }
        .header__menuList {
          column-gap: 40px;
        }
      }
    }
    @include mq($until: 380px){
      .social {
        align-items: flex-start;
        flex-direction: column;
        row-gap: 15px;
      }
    }
  }
}

.prefooter {
  height: 80vh;
  background-size: cover;
  background-position: center center;
  position: relative;
  @include mq($until: lg){
    aspect-ratio: 16/9;
    height: unset;
  }
  a {
      position: absolute;
      left: 8vw;
      top: 100px;
      @include mq($until: xl){
        left: 100px;
      }
      @include mq($until: lg){
        left: unset;
        right: 50px;
        top: 50px;
      }
      @include mq($until: sm){
        right: 30px;
        top: 30px;
      }
    
    img {
      background-color: #fff;
      padding: 20px;
      height: auto;
      @include mq($until: xl){
        max-width: 23vw;
      }
      @include mq($until: md){
        max-width: 33vw;
      }
    }
  }
}

.footer-small {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    @include mq($until: xs){
      flex-direction: column;
      row-gap: 10px;
    }
    a, span, p, div {
      color: #4E5360;
      font-size: 14px;
    }
    a:hover {
      color: var(--linkHover);
    }
    &__realization{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span{
        transition: color .3s ease-in-out;
      }
      &:hover span{
        color: var(--linkHover);
      }
      img{
        margin-left: 15px;
      }
    }
}

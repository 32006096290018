.hero {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 100px;
    min-height: 100vh;
    display: flex;
    align-items: center;
    &-slider {
        .video {
            width: 100%;
            height: 100%;    
            video {
                width: 100%;
                height: 100%;
                position: relative;
                top: unset;
                left: unset;
                min-width: unset;
                min-height: unset;
                transition: ease 0.3s all;
                transform: unset;
                display: block;            
            }
            @include mq($until: xs){
                margin-top: 40px;
            }
            .arrow {
                position: absolute;
                top: calc(100vh - 120px);
                padding: 20px;            
                left: 50%;
                z-index: 9;
                transform: translateX(-50%) rotate(180deg);
            }
        }
        @include mq($until: lg){
            // .video {
            //     height: unset;
            //     margin-top: 100px;
            //     video {
            //         position: relative;
            //         transform: unset;
            //         top: unset;
            //         left: unset;
            //         min-height: unset;
            //         width: 100%;
            //     }
            // }
            .slider {
                height: 100%;
                aspect-ratio: 16/9;
                margin-top: 100px;
            }
            .slider .slick-list {
                height: 100%;
            }
        }
    }
    &--small {
        min-height: unset;
        height: 60vh;
        @include mq($until: lg){
            aspect-ratio: 16/9;
            height: unset;
            padding-top: unset;
            padding-bottom: unset;
            .hero__content {
                h1 {
                    font-size: rem(38px);
                }
            }
        }
        @include mq($until: sm){
            aspect-ratio: 4/3;
            .hero__content {
                h1 {
                    font-size: rem(28px);
                }
            }
        }
    }
    &__content {
        position: relative;
        z-index: 1;
        max-width: 600px;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        align-items: flex-start;
        h1 {
            color: #fff;
            margin-bottom: 0;
            font-size: rem(42px);
            font-weight: 700;
        }
        h2 {
            color: #fff;
            margin-top: 0;
            margin-bottom: 0;
            font-size: rem(24px);
            font-weight: 700;
        }
        p {
            color: #fff;
            &:first-of-type {
                margin-top: 20px;
            }
        }
        a {
            display: block;
            align-self: unset;
            margin-top: 20px;
        }
        &--center {
            text-align: center;
            align-items: center;
            margin: 0 auto;
        }
        &--right {
            margin: 0 0 0 auto;
            text-align: right;
            align-items: flex-end;
        }
    }
}

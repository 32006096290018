.slider {
    width: 100%;
    position: relative;

    &:not(.custom) {
        .background {
            width: 100%;
            height: 100%;
            position: absolute;
            background-position: center;
            background-size: cover;
            z-index: 0;
        }
        .overlay {
            z-index: 1;
        }
    }

    &.full {
        height: 100vh;
        .slick-list {
            height: 100vh;
        }
    }
    &.full-width {
        .slick-slide {
            height: unset;
        }
    }
    &.a1 {
        .slick-list {
            aspect-ratio: 16/9;
        }
    }
    &.a2 {
        .slick-list {
            aspect-ratio: 4/3;
        }
    }
    &.a3 {
        .slick-list {
            aspect-ratio: 3/2;
        }
    }
    &.a4 {
        .slick-list {
            aspect-ratio: 21/9;
        }
    }
    &.space {
        .slick-slide {
            & > div {
                height: 100%;
                & > div {
                    height: 100%;
                }
            }
            .space {
                height: 100%;
                position: relative;
                margin: 0 10px;
            }
        }
    }
    &-asnav {
        .slick-slide {
            opacity: 0.8;
            cursor: pointer;
            transition: ease 0.3s all;
            &.slick-current, &:hover {
                opacity: 1;
            }
        }
    }
    &.arrows {
        &:hover .slick-arrow {
            opacity: 1;
        }
        .slick-arrow {
            opacity: 0;
        }
        &--sides-bottom .slick-arrow {
            top: unset;
            bottom: 20px;
            transform: unset;
            &.slick-prev {
                transform: rotate(180deg);
            }
        }
        &--sides-top .slick-arrow {
            top: 20px;
            transform: unset;
            &.slick-prev {
                transform: rotate(180deg);
            }
        }
        &--bottom-left .slick-arrow {
            top: unset;
            bottom: 20px;
            transform: unset;
            &.slick-prev {
                transform: rotate(180deg);
            }
            &.slick-next {
                left: 80px;
                right: unset;
            }
        }
        &--bottom-right .slick-arrow {
            top: unset;
            bottom: 40px;
            transform: unset;
            &.slick-prev {
                transform: rotate(180deg);
                right: 100px;
                left: unset;
            }
        }
        &--under {
            margin-bottom: 90px;
            .slick-arrow {
                top: unset;
                bottom: -70px;
                transform: unset;
                &.slick-prev {
                    transform: rotate(180deg);
                    left: 0;
                }
                &.slick-next {
                    right: 0;
                }
            }
        }
        &--under-right {
            margin-bottom: 90px;
            .slick-arrow {
                top: unset;
                bottom: -70px;
                transform: unset;
                right: 0;
                &.slick-prev {
                    transform: rotate(180deg);
                    right: 60px;
                    left: unset;
                }
            }
        }
        &--under-left {
            margin-bottom: 90px;
            .slick-arrow {
                top: unset;
                bottom: -70px;
                transform: unset;
                left: 0;
                &.slick-prev {
                    transform: rotate(180deg);
                }
                &.slick-next {
                    left: 60px;
                    right: unset;
                }
            }
        }
        &--above {
            margin-top: 90px;
            .slick-arrow {
                top: -70px;
                transform: unset;
                &.slick-prev {
                    transform: rotate(180deg);
                    left: 0;
                }
                &.slick-next {
                    right: 0;
                }
            }
        }
        &--above-right {
            margin-top: 90px;
            .slick-arrow {
                top: -70px;
                transform: unset;
                right: 0;
                &.slick-prev {
                    transform: rotate(180deg);
                    right: 60px;
                    left: unset;
                }
            }
        }
        &--above-left {
            margin-top: 90px;
            .slick-arrow {
                top: -70px;
                transform: unset;
                left: 0;
                &.slick-prev {
                    transform: rotate(180deg);
                }
                &.slick-next {
                    left: 60px;
                    right: unset;
                }
            }
        }
    }
    &.dots {
        &:hover .slick-dots {
            opacity: 1;
        }
        .slick-dots {
            opacity: 0;
            transition: ease 0.3s all;
        }
        &--h-on .slick-dots {
            position: absolute;
            bottom: 10px;
            margin: 0;
            left: 50%;
            transform: translateX(-50%);
        }
        &--v-onright .slick-dots {
            position: absolute;
            bottom: 50%;
            margin: 0;
            right: 10px;
            flex-direction: column;
            transform: translateY(50%);
        }
        &--v-onleft .slick-dots {
            position: absolute;
            bottom: 50%;
            margin: 0;
            left: 10px;
            flex-direction: column;
            transform: translateY(50%);
        }
    }
    &.slick-vertical {
        .slick-list, .slick-track {
            height: 100%!important;
        }
        .slick-slide {
            height: 100%;
        }
    }
    .slick-track {
        height: 100%;
    }
    .slick-slide {
        position: relative;
    }
    .slide-content {
        position: absolute;
        z-index: 2;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        align-content: center;
        text-align: center;
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 40px;
        padding-bottom: 40px;
        @include mq($until: md) {
            padding: 40px;
        }
        &[class*='right'] {
            align-content: flex-end;
            align-items: flex-end;
            text-align: right;
        }
        &[class*='left'] {
            align-content: flex-start;
            align-items: flex-start;
            text-align: left;
        }
        &[class*='top'] {
            justify-content: flex-start;
            padding-top: 100px;    
        }
        &[class*='bottom'] {
            justify-content: flex-end;
            padding-bottom: 100px;
        }
        * {
            font-size: 16px!important;
        }
        &.black .content {
            p, a:not(.button), span:not(.button), div:not(.button), h1,h2,h3,h4,h5,h6 {
                color: #000;
            }
        }
        &.white .content {
            p, a:not(.button), span:not(.button), div:not(.button), h1,h2,h3,h4,h5,h6 {
                color: #fff;
            }
        }
        &.global .content {
            p, a:not(.button), span:not(.button), div:not(.button), h1,h2,h3,h4,h5,h6 {
                color: $paragraph-color;
            }
        }
        &.color .content {
            p, a:not(.button), span:not(.button), div:not(.button), h1,h2,h3,h4,h5,h6 {
                color: $color-main;
            }
        }
        &.color-extra .content {
            p, a:not(.button), span:not(.button), div:not(.button), h1,h2,h3,h4,h5,h6 {
                color: $color-extra;
            }
        }
        .content {
            p, a, span, div, h1,h2,h3,h4,h5,h6 {
                color: #fff;
            }
            h1 {
                margin-bottom: 40px;
            }
        }
        .button {
            align-self: unset;
            margin-top: 40px;
        }
    }
}

.leaflet-container {
    height: 80vh;
    z-index: 0;
    .leaflet-marker-icon svg path[fill="#2d3e72"] {
        transition: ease 0.3s all;
    }
    .leaflet-marker-icon svg:hover path[fill="#2d3e72"] {
        fill: $color-main;
    }
    .map__popup {
        &-logo {
            height: 200px;
            background-position: center;
            background-repeat: no-repeat;
            background-color: $color-main;
            border-radius: var(--cardRadius, 0) var(--cardRadius, 0) 0 0;
        }
        &-image {
            height: 200px;
            background-position: center;
            background-repeat: no-repeat;
            background-color: $color-main;
            background-size: cover;
            border-radius: var(--cardRadius, 0) var(--cardRadius, 0) 0 0;
        }
        &-address {
            font-size: 14px;
        }
        &-content {
            padding: 15px;
        }
        h4 {
            font-size: 18px;
        }
        .button {
            display: block;
            margin-top: 20px;
        }
    }
    .leaflet-popup-content-wrapper, .leaflet-popup-tip {
        background: var(--cardBg, white);
    }
    .leaflet-popup-content-wrapper {
        border-radius: var(--cardRadius, 0);
    }
    .leaflet-popup-content {
        margin: 0;
    }
    .leaflet-tooltip {
        padding: 6px 15px;
        background-color: var(--buttonBgInitial, $color-main);
        border: var(--buttonBorder, 0) solid var(--buttonBorderInitial, $color-main);
        border-radius: var(--buttonRadius, 0);
        color: var(--buttonTxtInitial, $color-main);
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(10px);
        @include mq($until: md){
            width: 250px;
            white-space: normal;
            text-align: center;
        }
    }
    .leaflet-tooltip-top:before {
        border-top-color: var(--buttonBgInitial, $color-main);
    }
    .overlay {
        z-index: 401;
        mix-blend-mode: color;
    }
    .map-place .i {
        background: #fff;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        // border-radius: 15px;
        position: relative;
        height: 100%;
        box-shadow: 3px 4px 8px rgba(0,0,0, 0.05%);
        &:after {
            content: '';
            border-left:20px solid transparent;
            border-right:20px solid transparent;
            border-top:20px solid #fff;
            position: absolute;
            left: 20px;
            bottom: -20px;
        }
        &.arrow-bottom-right:after {
            right: 20px;
            left: unset;
        }
        &.arrow-bottom:after {
            top: 100%;
            bottom: unset;
            left: 50%;
            transform: translateX(-50%);
        }
        &.arrow-top:after {
            top: -20px;
            bottom: unset;
            border-bottom:20px solid #fff;
            border-top: 0;
        }
        &.arrow-top-right:after {
            top: -20px;
            bottom: unset;
            border-bottom:20px solid #fff;
            border-top: 0;
            right: 20px;
            left: unset;
        }
        div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        p {
            font-size: 12px;
            font-weight: 700;
            color: #333333;
            font-family: "Montserrat", serif;
            margin: 0;
            line-height: 12px;
        }
        span {
            font-size: 12px;
            font-weight: 700;
            color: #333333;
            font-family: "Montserrat", serif;
        }
        img {
        
        }
        svg {

        }
    }
}

.v-walk {
    padding-top: 160px;
    padding-bottom: 160px;
    position: relative;
    @include mq($until: xl){
        .padding-container-right {
            padding-right: 50px;
        }
    }
    @include mq($until: md){
        .padding-container-right {
            padding-right: 0;
        }
    }
    @include mq($until: lg){
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @include mq($until: sm){
        padding-top: 60px;
        padding-bottom: 60px;
    }
    &:after {
        background-image: url('/themes/default/assets/images/pattern.svg');
        //background-repeat: repeat-y;
        //background-size: cover;
        //background-position-y: bottom;
        background-attachment: fixed;
        position: absolute;
        right: -10vw;
        width: 35vw;
        top: 0;
        height: 100%;
        content: '';
        opacity: 0.2;
        z-index: 0;
    }
    &:before {
        position: absolute;
        left: 0;
        width: 35vw;
        bottom: 80px;
        height: 1px;
        content: '';
        background-color: var(--separator, var(--paletteColor5));
        @include mq($until: lg){
            bottom: 50px;
        }
        @include mq($until: md){
            display: none;
        }
    }
    &__content {
        position: relative;
        z-index: 1;
        @include mq($until: md){
            text-align: center;
        }
        h2 {
            margin-bottom: 50px;
        }
        p {
            line-height: rem(30px);
        }
    }
    &__box {
        aspect-ratio: 16/9;
        position: relative;
        z-index: 1;
        background-size: cover;
        background-position: center;
        margin-left: 4vw;
        margin-bottom: 60px;
        font-size: 0;
        cursor: pointer;
        @include mq($until: lg){
            margin-left: 50px;
        }
        @include mq($until: md){
            margin-left: 0;
            aspect-ratio: 4/3;
        }
        &:before {
            position: absolute;
            left: -2vw;
            top: 0;
            width: 1px;
            height: 100%;
            content: '';
            background-color: var(--separator, var(--paletteColor5));
            @include mq($until: md){
                display: none;
            }
        }    
        img {
            position: absolute;
            background-color: #fff;
            right: -12vw;
            top: 16vh;
            padding: 20px;
            height: auto;
            @include mq($until: xl){
                max-width: 20vw;
                right: -100px;
                top: 8vh;
            }    
            @include mq($until: lg){
                right: -50px;
            }    
            @include mq($until: md){
                display: none;
            }    
        }
        .bottom {
            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            .button {
                background-color: rgba(255, 255, 255, 0.9);
                color: var(--paletteColor1);
            }
        }
        iframe {
            display: none;
        }
    }
}

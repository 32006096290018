.localization {
    padding-top: 160px;
    padding-bottom: 160px;
    position: relative;
    @include mq($until: lg){
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @include mq($until: sm){
        padding-top: 60px;
        padding-bottom: 60px;
    }
    &--features {
        padding-top: 40px;
        &:after {
            display: none;
        }
        .icons-set {
            row-gap: 100px;
            @include mq($until: md){
                row-gap: 60px;
            }
            @include mq($until: sm){
                row-gap: 40px;
            }
            &__item {
                align-items: flex-start;
                column-gap: 100px;
                position: relative;
                overflow: unset;
                @include mq($until: md){
                    column-gap: 60px;
                }
                @include mq($until: sm){
                    flex-direction: column;
                    align-items: center;
                    &-content {
                        text-align: center;
                    }
                }
                &:first-of-type:before {
                    display: none;
                }
                &:before {
                    position: absolute;
                    left: -16vw;
                    width: 35vw;
                    top: -50px;
                    height: 1px;
                    content: '';
                    background-color: var(--separator, var(--paletteColor5));
                    @include mq($until: xl){
                        left: -200px;
                    }
                    @include mq($until: lg){
                        left: -100px;
                    }
                    @include mq($until: md){
                        display: none;
                    }
                }
                .image svg {
                    width: 122px;
                    height: 122px;
                    @include mq($until: md){
                        width: 80px;
                        height: 80px;
                        }
                    @include mq($until: sm){
                        width: 60px;
                        height: 60px;
                    }
                }
                p {
                    font-size: rem(28px);
                    margin-bottom: 20px;
                    @include mq($until: sm){
                        font-size: rem(18px);
                    }
                }
                span {
                    line-height: rem(30px);
                }
            }
        }
    }
    &:after {
        background-image: url('/themes/default/assets/images/pattern.svg');
        background-repeat: repeat-y;
        background-size: cover;
        position: absolute;
        left: 8vw;
        width: 35vw;
        top: 0;
        height: 100%;
        content: '';
        opacity: 0.2;
        @include mq($until: lg){
            left: 0;
        }
    }
    &__content {
        position: relative;
        z-index: 1;
        @include mq($until: md){
            padding: 0;
        }
        h2 {
            margin-bottom: 50px;
            @include mq($until: md){
                float: unset;
                margin-bottom: 40px;
            }
        }
        p {
            line-height: rem(34px);
        }
        .button {
            margin-top: 10vh;
            float: right;
            @include mq($until: lg){
                margin-top: 60px;
            }
            @include mq($until: md){
                float: unset;
                margin-top: 40px;
            }
        }
        .txtimg__image {
            //position: relative;
            @include mq($until: md){
                max-width: 400px;
            }
            & a:after {
                content: '';
                border: 1px solid var(--paletteColor1);
                width: calc(100% - 40px);
                height: calc(100% - 40px);
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
        }
    }
}

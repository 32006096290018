.places {
    position: relative;
    .leaflet-container {
        height: 550px!important;
    }
    .leaflet-control-layers {
        display: none;
    }
    .map-place {
        div.i {
            padding: 10px;
            background: var(--paletteColor3);
            &:after {
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid var(--paletteColor3);
            }
        }
        svg {
            width: 40px;
            height: 40px;
            fill: #fff;
        }
    }
    &__content {
        position: relative;
        z-index: 1;
        display: grid;
        grid-template-columns: auto auto;
        @include mq($until: xl){
            grid-template-columns: 1fr;
        }
    }
    &__desc {
        background-color: var(--paletteColor3);
        padding-top: 120px;
        padding-bottom: 120px;
        padding-right: 100px;
        @include mq($until: xl){
            padding-top: 60px;
            padding-bottom: 60px;
        }
        @include mq($until: md){
            padding-right: 50px;
        }
        @include mq($until: sm){
            padding-right: 30px;
        }
        @include mq($until: xs){
            padding-right: 20px;
        }
        &-single {
            display: none;
            &.active {
                display: block;
            }
            p {
                color: #fff;
            }
        }
    }
    &__types {
        padding-top: 120px;
        padding-bottom: 120px;
        padding-left: 100px;
        display: grid;
        grid-template-columns: auto auto;
        justify-items: start;
        align-items: start;
        column-gap: 40px;
        border-bottom: 1px solid  var(--paletteColor3);
        border-top: 1px solid  var(--paletteColor3);
        position: relative;
        @include mq($until: xl){
            display: flex;
            flex-wrap: wrap;
            row-gap: 20px;
        }
        @include mq($until: xl){
            order: -1;
            padding-top: 40px;
            padding-bottom: 40px;
        }
        @include mq($until: md){
            padding-left: 50px;
        }
        @include mq($until: sm){
            padding-left: 30px;
        }
        @include mq($until: xs){
            padding-left: 20px;
        }
        &:after {
            background-image: url('/themes/default/assets/images/pattern.svg');
            // background-repeat: repeat-y;
            // background-position-y: bottom;
            background-attachment: fixed;
            position: absolute;
            right: -10vw;
            width: 35vw;
            top: 0;
            height: 100%;
            content: '';
            opacity: 0.2;
            z-index: 0;
        }
        &-single {
            cursor: pointer;
            z-index: 1;
            position: relative;
            white-space: nowrap;
            &.active {
                color: var(--paletteColor1);
                &:after {
                    width: 100%;
                }
            }
            font-size: rem(21px);
            font-weight: 600;
        }
        & > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 20px;
            @include mq($until: xl){
                flex-wrap: wrap;
                flex-direction: row;
                column-gap: 30px;
            }    
        }
    }
}

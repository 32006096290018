.interiors {
    position: relative;
    &:after {
        background-image: url('/themes/default/assets/images/pattern.svg');
        //background-repeat: repeat-y;
        //background-size: cover;
        //background-position-y: top;
        background-attachment: fixed;
        position: absolute;
        right: -10vw;
        width: 35vw;
        top: 0;
        height: 100%;
        content: '';
        opacity: 0.2;
        z-index: 0;
    }
    &__content {
        position: relative;
        z-index: 1;
        .row {
            display: grid;
            grid-template-columns: minmax(300px, 500px) minmax(300px, 600px);
            column-gap: 100px;
            @include mq($until: lg){
                grid-template-columns: minmax(300px, 40%) minmax(300px, 600px);
                column-gap: 60px;
            }
            @include mq($until: md){
                grid-template-columns: 1fr;
                text-align: center;
            }
            .col-lg-6 {
                margin: 0;
                padding: 0;
                width: 100%;
                max-width: 100%;            
            }
        }
        .slider {
            margin-top: 100px;
            @include mq($until: sm){
                margin-top: 60px;
            }
            .slick-arrow {
                bottom: 100px;
                background-color: var(--buttonBgInitial);
                line {
                    stroke: var(--buttonTxtInitial);
                }
                &:hover {
                    background-color: var(--paletteColor3);
                    fill: #fff;
                    stroke: #fff;
                }
            }
            .slide-content {
                display: block;
                position: relative;
                padding: 0;
                @include mq($until: md){
                    width: unset;
                }
                @include mq($until: sm){
                    width: 100%;
                }
                .content {
                    &:after {
                        width: 1px;
                        height: 150%;
                        content: '';
                        left: 0;
                        bottom: 0;
                        position: absolute;
                        background-color: var(--paletteColor1);
                    }
                    padding-left: 20px;
                    h3 {
                        color: var(--h3, var(--headings, var(--txt)));
                        font-size: rem(26px)!important;
                    }
                    p {
                        color: var(--txt);
                    }
                }
            }
            .slick-slide {
                & a > div > div {
                    display: flex;
                    flex-direction: column-reverse;
                    row-gap: 20px;
                    @include mq($until: md){
                        align-items: center;
                    }

                }
                img {
                    transition: ease 0.3s all;
                    width: auto;
                    height: auto;
                    @include mq($until: md){
                        max-width: 400px;
                    }
        
                }
                &:hover img {
                    transform: scale(1.05);
                }
            }
        }
    }
    &__carousel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        z-index: 1;
        padding-top: 160px;
        padding-bottom: 160px;
        @include mq($until: lg){
            padding-top: 100px;
            padding-bottom: 100px;
        }
        @include mq($until: sm){
            padding-top: 60px;
            padding-bottom: 60px;
        }
    }
    &__carousel .slick-slide {
        margin-left: -100px;
        transition: ease 0.3s all;
        //max-width: 700px;
        //max-height: 40vh;
        // width: auto!important;
        // aspect-ratio: 1/1;    
        // max-height: 60vh;
        //box-shadow: 0 3px 21px rgba(9, 52, 82, 0.15);
        width: auto!important;
        &:nth-child(2n) {
            margin-top: 16vh;
        }
        &:last-of-type {
            margin-left: 0;
        }
        &:first-of-type {
            margin-left: 0;
        }
        a > div:not(.v):after {
            content: '';
            border: 1px solid var(--paletteColor1);
            width: calc(100% - 40px);
            height: calc(100% - 40px);
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
        .v {
            &.top {
                z-index: 1;
                position: relative;
            }
            display: flex;
            justify-content: center;
            & > div {
                position: relative;
                &:after {
                    content: '';
                    border: 1px solid var(--paletteColor1);
                    width: calc(100% - 40px);
                    height: calc(100% - 40px);
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }
            }
            img {
                // width: 40px;
            }
        }
        // &:nth-child(3n+1) {
        //     .background {
        //         aspect-ratio: 1/2;
        //     }
        // }
        // .background {
        //     height: auto;
        //     aspect-ratio: 16/9;
        // }
        // .v {
        //     img {
        //         //height: 50vh;
        //         //width: auto;
        //         margin: 0 auto;
        //     }
        // }
        .top {
            z-index: 1;
        }
        img {
            width: auto;
            height: 40vh;
            margin: 0 auto;
            // &.v {
            //     height: 40vh;
            //     width: auto;
            // }
            @include mq($until: xxl){
                aspect-ratio: 16/9;
                object-fit: cover;
            }
        }
        &:hover {
            z-index: 2;
            transform: scale(1.05);
        }
    }
    &__carousel .slick-track {
        padding: 20px 0;
    }
    &__carousel .slick-arrow {
        background-color: var(--buttonBgInitial);
        line {
            stroke: var(--buttonTxtInitial);
        }
        &:hover {
            background-color: var(--paletteColor3);
            fill: #fff;
            stroke: #fff;
        }
    }
    &__carousel .slick-slide * {
        font-size: 0;
        //border-radius: 3px;
    }

}

@include mq($until: xl) {
    .interiors {
        &__carousel .slick-slide {
            max-width: 600px;
        }    
    }
}
@include mq($until: lg) {
    .interiors {
        &__carousel .slick-slide {
            margin-left: -18vw;
        }    
    }
}

@include mq($until: sm) {
    .interiors {
        &__carousel .slick-slide {
            margin-left: -24vw;
            max-width: 400px;
        }    
    }
}

@include mq($until: xs) {
    .interiors {
        &__carousel .slick-slide {
            max-width: 300px;
        }    
    }
}

.flats {
  //position: relative;
  &__content {
    background-color: var(--paletteColor3);
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .flat-list {
    &__head-item {
    }

    &__item {
      &-value {
        span {
          color: #fff;
        }
        a {
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        svg {
          fill: #fff;
          path {
            fill: #fff;
          }
        }
        #virtual path {
          stroke: var(--paletteColor3);
        }
      }
      &-label {
        color: #fff;
      }
    }
  }
}

.flat-widget {
  @include mq($until: md){
    overflow: auto;
  }
  svg {
    width: 100%;
    height: auto;
    @include mq($until: md){
      width: auto;
      height: 50vh;
    }
  }
  .house-widget {
    fill: var(--paletteColor1);
    opacity: 0;
    cursor: pointer;
    transition: ease 0.3s all;
    rect {
      fill: unset;
    }

    &:hover, &.active {
      opacity: 0.75;
      cursor: pointer;
    }
  }
  &__tooltip {
    pointer-events: none;
    position: absolute;
    font-size: 16px;
    text-align: center;
    background: var(--paletteColor3);
    padding: 10px 10px;
    z-index: 5;
    min-height: 30px;
    line-height: 23px;
    margin: -20px auto 0 auto;
    color: #fff;
    border-radius: 0;
    //box-shadow: 0 0 0 1px #eee;
    transform: translateX(-50%);
    display: none;
    box-sizing: unset;
    &.active {
      display: block;
    }
    b {
      color: var(--paletteColor1);
    }
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 100%;
      width: 0;
      height: 0;
      margin-left: -8px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid var(--paletteColor3);
    }
    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 100%;
      width: 0;
      height: 0;
      margin-left: -10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid var(--paletteColor3);
    }
  }
}

.flat-list {
  &__head, &__item {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr 1fr 2fr 2fr;
    text-align: center;
    gap: 5px;
    align-items: center;
  }
  &__head {
    margin-bottom: 20px;
    @include mq($until: md) {
      display: none;
    }
  }
  &__head-item {
    color: var(--paletteColor1);
    font-weight: 600;
    font-size: rem(20px);
    &--action {
      cursor: pointer;
    }
    span {
      color: var(--paletteColor1);
      font-weight: 600;
      font-size: rem(20px);
      @include mq($until: 1800px){
        display: none;
      }
    }
  }

  &__list {
    //position: relative;
    @include mq($until: md) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
      row-gap: 20px;
    }
    @include mq($until: sm)  {
      grid-template-columns: 1fr;
    }
  }
  
  &__item {
    border-bottom: 1px solid var(--paletteColor1);
    padding: 10px 0;
    transition: all .3s;
    @include mq($until: md) {
      grid-template-columns: 1fr;
      text-align: left;
      padding: 20px;
      row-gap: 10px;
      & > span {
        display: flex;
        justify-content: space-between;
      }
    }
    @include mq($until: 420px) {
      & > span {
        justify-content: flex-start;
        flex-wrap: wrap;
        column-gap: 10px;
      }
    }
    .link {
      color: var(--link);
      cursor: pointer;
    }
    &.sprzedane > span {
      opacity: 0.4;
    }
    &.active, &:hover {
      background-color: var(--paletteColor1);
      * {
        color: white;
      }
      svg {
        fill: #fff;
        path {
          fill: #fff;
        }
      }
      #virtual path {
        stroke: var(--paletteColor1);
      }
    }
    svg {
      width: auto;
      height: 24px;
      fill: var(--icon, var(--txt));
      margin-left: 10px;
      path {
        fill: var(--icon, var(--txt));
      }
      #virtual path {
        stroke: #fff;
      }
    }
  }
  &__item-label {
    display: none;
    color: var(--headings, var(--txt));
    font-weight: 700;
    @include mq($until: md) {
      display: block;
    }
  }
}

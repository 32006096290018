.prestige {
    background-color: var(--paletteColor4);
    padding-top: 160px;
    padding-bottom: 160px;
    position: relative;
    @include mq($until: lg){
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @include mq($until: sm){
        padding-top: 60px;
        padding-bottom: 60px;
    }
    &:after {
        background-image: url('/themes/default/assets/images/pattern.svg');
        background-repeat: repeat-y;
        background-size: cover;
        position: absolute;
        left: 8vw;
        width: 35vw;
        top: 0;
        height: 100%;
        content: '';
        opacity: 0.2;
        @include mq($until: lg){
            left: 0;
        }
    }
    &__content {
        position: relative;
        z-index: 1;
        @include mq($until: md){
            padding: 0;
        }
        h2 {
            color: #fff;
            margin-bottom: 50px;
            @include mq($until: md){
                float: unset;
                margin-bottom: 40px;
            }
        }
        p {
            color: #fff;
            line-height: rem(34px);
        }
        .button {
            margin-top: 10vh;
            float: right;
            @include mq($until: lg){
                margin-top: 60px;
            }
            @include mq($until: md){
                float: unset;
                margin-top: 40px;
            }
        }
        @include mq($until: md){
            .txtimg__slider  {
                max-width: 400px;
            }
        }
        .article__slide {
            //position: relative;
            &:after {
                content: '';
                border: 1px solid var(--paletteColor1);
                width: calc(100% - 40px);
                height: calc(100% - 40px);
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
        }
    }
}

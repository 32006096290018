.subsite {
    .header {
        box-shadow: 1px 1px 20px rgba(48, 58, 114, 0.05);
    }
    &__content {
        padding-top: 100px;
        padding-bottom: 100px;
        h1 {
            margin-bottom: 80px;
        }
    }
}

.about {
    padding-top: 160px;
    padding-bottom: 160px;
    position: relative;
    @include mq($until: lg){
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @include mq($until: sm){
        padding-top: 60px;
        padding-bottom: 60px;
    }
    &:after {
        background-image: url('/themes/default/assets/images/pattern.svg');
        //background-repeat: repeat-y;
        //background-size: cover;
        //background-position-y: bottom;
        background-attachment: fixed;
        position: absolute;
        right: -10vw;
        width: 35vw;
        top: 0;
        height: 100%;
        content: '';
        opacity: 0.2;
        z-index: 0;
    }
    &__content {
        position: relative;
        z-index: 1;
        width: 50%;
        margin-bottom: 40px;
        @include mq($until: md){
            padding: 0;
            text-align: center;
            width: 100%;
        }
        h2 {
            margin-bottom: 40px;
        }
        p {
            line-height: rem(30px);
        }
    }
    &__persons {
        position: relative;
        z-index: 1;
        @include mq($until: md){
            padding: 0;
        }
        .txtimg {
            &:first-of-type {
                margin-bottom: 140px;
                @include mq($until: md){
                    margin-bottom: 60px;
                }
                &:before {
                    top: 0px;
                }    
            }
            &:before {
                position: absolute;
                left: -16vw;
                width: 35vw;
                top: -70px;
                height: 1px;
                content: '';
                background-color: var(--separator, var(--paletteColor5));
                @include mq($until: xl){
                    left: -200px;
                }
                @include mq($until: lg){
                    left: -100px;
                }
                @include mq($until: md){
                    display: none;
                }
            }
            .txtimg__image {
                //position: relative;
                @include mq($until: md){
                    max-width: 400px;
                }    
                &:after {
                    content: '';
                    border: 1px solid var(--paletteColor1);
                    width: calc(100% - 40px);
                    height: calc(100% - 40px);
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }
            }
    
        }
        h3 {
            font-size: rem(34px);
            margin-bottom: 40px;
        }
        p {
            line-height: rem(30px);
        }
    }
}

.catalog-list--boxed .catalog-card {
    background: var(--cardBg, #fff);
    border-radius: var(--cardRadius, 0);
    box-shadow: 1px 1px 20px rgba(48, 58, 114, 0.05);
    &.image-above {
        .catalog-card__content {
            padding: 25px;
        }
    }
    &.image-left {
        .catalog-card__content {
            padding: 25px;
        }
    }
    &.image-right {
        .catalog-card__content {
            padding: 25px;
        }
    }
    &__content {
        padding: 25px;
    }
}

.catalog-card {
    overflow: hidden;
    transition: ease 0.3s all;
    position: relative;
    display: flex;
    flex-direction: column;
    &[data-hover]{
        display: flex;
    }
    &.image-above {
        .catalog-card__content {
            padding: 25px 0 0 0;
        }
    }
    &.image-left {
        display: grid;
        grid-template-columns: 1fr 1fr;
        .catalog-card__content {
            padding: 0 0 0 25px;
        }
    }
    &.image-right {
        display: grid;
        grid-template-columns: 1fr 1fr;
        .catalog-card__top {
            order: 1;
        }
        .catalog-card__content {
            padding: 0 25px 0 0;
        }
    }
    /*&:hover {
        box-shadow: 1px 1px 20px rgba($color-extra, 0.2)
    }*/
    &__top {
        position: relative;
        overflow: hidden;
        .catalog-card__price {
            &.foto-left {
                right: unset;
                left: 0;
                border-radius: 0 var(--cardRadius, 0) var(--cardRadius, 0) 0;
            }
            position: absolute;
            top: 40px;
            right: 0;
            color: $paragraph-color;
            background-color: #ffffff;
            border-radius: var(--cardRadius, 0) 0 0 var(--cardRadius, 0);
            font-size: rem(14px);
            padding: 10px 15px;
            strong {
                text-transform: uppercase;
                font-weight: 700;
            }
        }
        [data-hover] {
            display: block;
        }
    }
    &__logo {
        height: 200px;
        background-position: center;
        background-repeat: no-repeat;
        background-color: $color-main;
    }
    &__image {
        width: 100%;
        //height: 200px;
        background-position: center;
        background-repeat: no-repeat;
        background-color: $color-main;
        background-size: cover;
        border-radius: var(--cardRadius, 0);
    }
    &__price {
        color: $paragraph-color;
        font-size: rem(14px);
        strong {
            text-transform: uppercase;
            font-weight: 700;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        flex: 1;
        z-index: 1;
        justify-content: space-between;
        row-gap: 20px;
        &.align-center {
            text-align: center;
        }
        &.align-right {
            text-align: right;
        }
    }
    &__list {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    span {
        font-size: 15px;
        font-weight: 500;
        color: $paragraph-color;
        display: block;
        svg {
            width: 20px;
            height: auto;
            fill: $color-main;
            margin-right: 10px;
            path {
                fill: $color-main;
            }
        }
    }
    &__address {
        font-size: 15px;
        font-weight: 500;
        color: $color-main;
        svg {
            width: 20px;
            fill: $color-main;
            margin-right: 10px;
        }
    }
    &__cats {
        &--single {
            display: inline-block;
            margin-top: 5px;
            span {
                background-color: $color-main;
                color: #fff;
                padding: 5px 10px;
                border-radius: 5px;
                text-transform: uppercase;
                font-size: 13px;
            }
        }
    }
    &__head {
        font-size: rem(22px);
        margin-top: 0;
        margin-bottom: 0;
        transition: ease 0.3s all;
        &:hover {
            color: $color-main;
        }
    }
    &__footer {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: flex-end;    
    }
    &__more {
        display: flex;
        gap: 10px;
        &.block {
            flex-direction: column;
            .button {
                width: 100%;
                &:last-of-type {
                    flex: auto;
                }
                &.icon {
                    padding: 14px 20px;
                }
            }
        }
        .button {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
            &:last-of-type {
                flex: 1;
                flex-direction: column;
                row-gap: 5px;
                strong {
                    font-weight: 400;
                }
            }
            &--border {
                &:hover {
                    border-color: $color-extra;
                }
                /*border-color: $color-main;
                color: $color-main;*/
            }
            &.icon {
                padding: 5px 12px;
            }
            &:hover {
                svg {
                    fill: #fff;
                }
            }
            svg {
                width: 40px;
                height: auto;
                fill: $paragraph-color;
                transition: ease 0.3s all;
            }
        }
    }
}

.features {
    padding-top: 160px;
    padding-bottom: 160px;
    position: relative;
    @include mq($until: lg){
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @include mq($until: sm){
        padding-top: 60px;
        padding-bottom: 60px;
        .separator.m6 {
            margin-top: 60px;
            margin-bottom: 60px;        
        }
    }

    &__content {
        position: relative;
        z-index: 1;
        h2 {
            text-align: center;
            margin-bottom: 0;
        }
        .icons-set {
            row-gap: 60px;
            column-gap: 80px;
            @include mq($until: xl){
                column-gap: 40px;
            }
            @include mq($until: lg){
                grid-template-columns: repeat(2, 1fr);
                row-gap: 40px;
            }
            @include mq($until: sm){
                column-gap: 20px;
            }
            @include mq($until: 480px){
                grid-template-columns: 1fr;
            }
            .image {
                z-index: 1;
                position: relative;
            }
            &__item {
                @include mq($until: sm){
                    flex-direction: column;
                }
                &-content {
                    z-index: 0;
                    position: relative;
                    @include mq($until: sm){
                        text-align: center;
                    }    
                }
            }
            svg {
                width: 100px;
                height: 100px;
                @include mq($until: xl){
                    width: 60px;
                    height: 60px;
                }
            }
        }
    }
}
